
<div class="innerHeader capabilities careers">
  <div class="innerHeadings">
    <!-- <h3>CAREERS</h3> -->
    <h1>Join <span> Us</span></h1>
  </div>
</div>
<app-cirlelinks></app-cirlelinks>
<div class="commonContents">
  <div class="careerColumns">
    <div class="graybox grayBg">
      <h3 class="redBg">Build Your Career with JTSi</h3>
      <p>
        The dynamic nature and positive outlook are what we look forward to in an employee. In JTSi we have employees who have vast exposures as well as young talented minds. Our resources with exceptional capabilities work together in achieving common goals. They are the pillars who bring quality and value to our delivery and business. JTSi gives great appreciation and value to employees who are innovative, think out of the box type and exceptionally creative in their work and deliverables. We are, because of our employees and our clients have huge satisfaction in what our teams have delivered over the years.
      </p>
      <p>
        In JTSi, employee life and work balance is ensured and if you are in the lookout for a career with the ideal work-life balance, JTSi is the perfect destination. We have a flexible work environment and a laid-back working style that will consequently deliver the most productive outcome from the employees. For graduates who look for kick-starting their career, JTSi offers the association of experienced employees and assignments that helps you well-verse with latest technologies. JTSi believes in continuous training and continuous improvement of our employees thus polishing their skills quite often which will mutually benefit the employee and the company.
      </p>
      <p>
        Our collective success at JTSi depends on the wellbeing of everyone on our team. We are committed to providing stimulating working conditions for our employees to help them perform at their best, including a safe and pleasant work environment, competitive remuneration, social protection, and respect for work-life balance. All our employees are encouraged to develop their professional and personal skills.
      </p>
      <p>
        With JTSi, your potential is endless. Our employees are fully supported in their endeavors to achieve their goals. We encourage our people to dive in, roll up their sleeves and take on the many opportunities bound to come their way.
</p>
<p>
We invite you to join us at JTSi in sharing our vision for excellence in everything we do. Count on us to deliver every time, as our team serves our clients with precision and reliability. Entrepreneurial and innovative, our team goes beyond today's problems to solve tomorrow's challenges, enabling our clients to gain a competitive edge and achieve their respective business goals.

      </p>
   
      <div class="benefits">
        <h4>Employee Benefits</h4>
        <div class="iconBenefits">
            <div class="iconbox">
                <span>
                <svg>
                    <use xlink:href="../../assets/img/sprite.svg#customerFirst"></use>
                  </svg>
                </span>
                <h5>Employee Health Insurance</h5>
                  
            </div>
    
            <div class="iconbox">
                <span>
                <svg>
                    <use xlink:href="../../assets/img/sprite.svg#contracts"></use>
                  </svg>
                </span>
                  <h5>Annual Privilege Leaves</h5>
                  
            </div>
    
            <div class="iconbox">
                <span>
                <svg>
                    <use xlink:href="../../assets/img/sprite.svg#weDo"></use>
                  </svg>
                </span>
                  <h5>Team Lunch</h5>
                  
            </div>
    
            <div class="iconbox">
                <span>
                <svg>
                    <use xlink:href="../../assets/img/sprite.svg#outdoor"></use>
                  </svg>
                </span>
                  <h5>Team Outing</h5>
                  
            </div>
    
    
        </div>
    </div>
    </div>
    <div class="openings">
      <h3 class="darkBg">Current Openings</h3>
      <div *ngFor="let career of reqList; let i=index;">
        <h4 *ngIf="!reqList[i-1] || career.department!=reqList[i-1].department">{{career.department}}</h4>
        <div [ngClass]="{' open':i==0,' closed':i>0 }" class="vacancies">
          <h5 class="designation">{{career.jobTitle}}</h5>
          <div class="viewdetails ">
            <p>
              Qualification:{{career.qualification}}
              <br>
              Experience:{{career.experience}}
              <br>
              Skillsets: {{career.skillset}}
              <br>
              Remuneration: {{career.remuneration}}
              <br>
              Location : {{career.location}}
              <br>
              E Mail : {{career.emailID}}
            </p>

            <button data-id={{career.reqID}} (click)="showApplyNow(career.reqID,applynow)" 
            data-bs-toggle="modal" data-bs-target="#applyjob" id="apply-now-button1" >Apply Now</button>
          </div>
        </div>
      </div>







    </div>
  </div>
</div>



<!-- Modal -->
<div class="modal fade" id="applyjob" tabindex="-1" aria-labelledby="applyjobLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Apply For This Job</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="ltsTalk">
          <div class="filltheForm">
            <form class="form" novalidate #applynow="ngForm" (ngSubmit)="applynow.form.valid && onSubmit(applynow)">
              <div class="formGroup">
                <label>Full Name</label>
                <input type="hidden" name="boReqID" class="form-control" #res_BOReqID="ngModel" [(ngModel)]="applynowservice.formData.res_BOReqID">
                <input type="text" name="fullname" #res_Name="ngModel" [(ngModel)]="applynowservice.formData.res_Name"
                       class="form-control" [ngClass]="{'is-invalid': applynow.submitted &&  res_Name.invalid}" required maxlength="50">
              </div>
              <div class="formGroup">
                <label>Email</label>
                <input type="email" name="email" #res_Email="ngModel" [(ngModel)]="applynowservice.formData.res_Email"
                       class="form-control" [ngClass]="{'is-invalid': applynow.submitted &&  res_Email.invalid}" required maxlength="100">
              </div>
              <div class="formGroup">
                <label>Phone Number</label>
                <input type="text" ng2TelInput name="phoneNumber" #res_Phone="ngModel" [(ngModel)]="applynowservice.formData.res_Phone"
                       class="form-control" [ngClass]="{'is-invalid': applynow.submitted &&  res_Phone.invalid}" required maxlength="20"
                       (hasError)="hasError($event,applynow)"
                       (countryChange)="onContryChange($event,applynow)">
                <input type="hidden" name="countryCode" class="form-control" #CountryCode="ngModel" [(ngModel)]="applynowservice.formData.countryCode">
              </div>
              <div class="formGroup">
                <label>Your Message</label>
                <textarea cols="5" rows="5" name="message" #res_Message="ngModel" [(ngModel)]="applynowservice.formData.res_Message"
                          maxlength="3000"></textarea>
              </div>
              <div class="formGroup">
                <label class="" for="customFile">Upload Your Resume</label>
                <input name="fileuploadfield" type="file" (change)="onFileSelected($event,applynow)">
                <input type="hidden" name="fileuploaded" #res_File="ngModel" [(ngModel)]="applynowservice.formData.res_File">
                <!--button type="button" class="fileButton" (click)="uploadFiles()" >Upload</button -->
              </div>

              <button class="btn btn-md btn-primary" type="submit">Submit Here</button>
            </form>
          </div>
          <div class="addresses">
            <div class="contacts">
                <i class="icon ion-md-call"></i><a href="tel:+12024645874"> +1(202)464-JTSi(5874)</a>
                <i class="icon ion-md-mail"></i> <a href="mailto:jtsiindia.com">info@jtsiindia.com </a>
                </div>
                <h4>KOCHI-INDIA OFFICE </h4>
                <ul>
                <li>Infopark, Kakkanad,</li>
                <li>Kochi – 682042, Kerala, India.</li>
                <li class="bold"><i class="icon ion-md-call"></i><a href="tel:+914844070368"> +91 484 407-0368 </a></li>
                </ul>
                
                <h4>BANGALORE-INDIA OFFICE</h4>
                <ul>
                <li>Doresani Palya, Near Kalyani Infopark,</li>
                <li>Bengaluru, Karnataka, India (HQ).</li>
                <li class="bold"><i class="icon ion-md-call"></i><a href="tel:+918041114111"> +91 80 4111-4111 </a></li>
                </ul>
         
          <h4>Other ways to contact us</h4>
          <ul>
            <li><a [routerLink]="['/contact']"  data-bs-dismiss="modal">Find an office </a></li>
            <li><a [routerLink]="['/careers']"  data-bs-dismiss="modal">Search jobs and submit your resume </a></li>
            <li class="findUs">
              <a target="_blank" href="https://www.facebook.com/gsti04/"><i class="icon ion-logo-facebook"></i> </a>
              <a target="_blank" href="https://www.linkedin.com/in/jtsiindia/"><i class="icon ion-logo-linkedin"></i> </a>
              <a target="_blank" href="https://twitter.com/gstitechindia?lang=en"><i class="icon ion-logo-twitter"></i> </a>
            </li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
